
.flatpickr-calendar {

  overflow: hidden;
  box-shadow: 0px 5px 15px rgba($black, 0.75);

  .flatpickr-months {
    background: $primary-color;

    .flatpickr-current-month {
      color: $white;
    }

    .flatpickr-prev-month, .flatpickr-next-month {
      svg, &:hover svg {
        fill: $white;
      }
    }

    .flatpickr-month {
      height: 34px;
    }

    input.cur-year {
      box-shadow: none;
    }
  }

  .flatpickr-weekdays {
    background: $primary-color;
    border-bottom: #e9e9e9 solid 1px;
    .flatpickr-weekday {
      color: $white;
    }
  }

  // Important hack for IE11 flexbox
  span.flatpickr-day {
    max-width: 43px;
    max-width: unset;
  }

  .flatpickr-day {

    &.disabled {
      border-right-color: transparent;
      &:hover {
        border-top-color: transparent;
      }
    }
    
    &.selected {
      background-color: $primary-color;
      border-color: transparent;
    }

    &.today:not(.selected) {
      color: $success-color;
      border-bottom-color: $success-color;
      &:hover {
        background-color: $secondary-color;
        border-color: $success-color;
      }
    }
  }
}

