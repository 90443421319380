#promo-banner {

  &.preshow {
    max-height: 0;
    padding: 0;
  }

  transition: all 0.75s ease-in;
  background: $black;
  padding: 0.5rem;
  overflow-y: hidden;
  color: $medium-gray;
  max-height: 20rem;

  a, strong {
    color: $white;
  }

  svg {
    width: 23px;
    fill: $white;
    color: $white;
    margin-left: 10px;
    vertical-align: middle;
  }
}
