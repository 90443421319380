#newpop {
  margin-top: 3rem;
  padding: 3rem 0;
  background: $primary-color url(/images/newpop-bg.png) no-repeat 50% 50% / contain;
  color: $white;
  a {
    color: $white;
    display: block;
    padding: .5rem 0;
    &:hover {
      text-decoration: underline;
    }
  }
  ul {
    list-style: none outside;
    margin: 0;
  }
  @include breakpoint(small only) {
    background: $primary-color url(/images/newpop-bg.png) no-repeat 50% 50%;
    ul {
      font-size: 80%;
    }
  }
}

#site-footer {
  background: $secondary-color;
  font-weight: 600;

  .links {
    a {
      color: $black;
    }

    .cell {
      font-size: 90%;
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    .fa-icon {
      padding: 0.25rem;
      &:first-child {
        margin-right: 0.85rem;
      }
    }
    @include breakpoint(small only) {
      padding-left: 1rem;
    }
  }

  .legal {
    color: $dark-gray;
    margin-top: 1rem;
    font-size: 0.75rem;
    @include breakpoint(medium) {
      font-size: 1rem;
    }
  }

  .social {
    background: $primary-color;
    color: $white;
    padding-left: 2rem;
    padding-bottom: 2rem;
    h4 {
      font-weight: bold;
    }
    p {
      font-size: 85%;
    }
    .fa-icon {
      color: $white;
      padding: 0.25rem 0.5rem;
      &:first-child {
        margin-right: 1rem;
      }      
    }
  }
}
